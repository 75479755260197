import React, { useEffect } from 'react'
import gsap from 'gsap'
import styles from './Slideshow.module.scss'

const Slideshow = () => {
  useEffect(() => {
    // Get window width
    const currentWidth = window.innerWidth

    // Breakpoint
    const breakpoint = window.matchMedia('(min-width: 740px)')

    // Set hero width
    const heroItems = document.querySelectorAll('.hero__bg__item')
    if (breakpoint.matches === false) {
      // For medium down
      heroItems.forEach(item => {
        item.style.backgroundSize = `${currentWidth * 1.2}px, auto`
      })
    } else {
      heroItems.forEach(item => {
        item.style.backgroundSize = `${currentWidth}px, auto`
      })
    }

    // Hero Animation
    const dur = 7
    const tl = gsap.timeline()
    Animate()

    function Animate() {
      const tl = gsap.timeline()
      const Hero1 = document.querySelector('.hero1')
      const Hero2 = document.querySelector('.hero2')
      const Hero3 = document.querySelector('.hero3')
      tl.set(Hero1, { zIndex: 1 })
        .to(Hero1, { duration: dur, x: 40, ease: 'none' })
        .to(Hero1, {
          delay: -0.5,
          duration: 0.5,
          width: 0,
          onComplete: function () {
            console.log('finish'),
              gsap.set(Hero1, {
                x: 0,
                width: '100%',
                zIndex: '',
              })
            gsap.set(Hero3, {
              zIndex: '-1',
            })
          },
        })

        .set(Hero2, { zIndex: 1 })
        .to(Hero2, {
          delay: -1,
          duration: dur,
          ease: 'none',
          y: -40,
        })
        .to(Hero2, {
          delay: -0.5,
          duration: 0.5,
          width: 0,
          onComplete: function () {
            gsap.set(Hero2, {
              y: 0,
              width: '100%',
              zIndex: '',
            })
            gsap.set(Hero1, {
              zIndex: '-1',
            })
          },
        })

        .set(Hero3, { zIndex: 1 })
        .to(Hero3, {
          delay: -1,
          duration: dur,
          ease: 'none',
          transformOrigin: 'left bottom',
          scale: 1.05,
          onComplete: function () {
            gsap.set(Hero3, {
              scale: 1,
              width: '100%',
              zIndex: '',
            })
            gsap.set(Hero2, {
              zIndex: '-1',
            })
          },
        })
        .to(Hero3, { delay: -1, duration: 0.5, width: 0 })
      tl.call(Animate, null, '-=1')
    }
    tl.call(Animate, null, '-=1')
  })

  return (
    <div className={`hero ${styles.hero}`}>
      <div className={`hero__bg ${styles.hero__bg}`}>
        <div className={`hero__bg__inner ${styles.hero__bg__inner}`}>
          <div
            className={`hero__bg__item hero1 ${styles.hero__bg__item} ${styles.hero1}`}
          ></div>
          <div
            className={`hero__bg__item hero2 ${styles.hero__bg__item} ${styles.hero2}`}
          ></div>
          <div
            className={`hero__bg__item hero3 ${styles.hero__bg__item} ${styles.hero3}`}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default Slideshow
