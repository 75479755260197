import React from 'react'
import Layout from '../../components/Demo/Layout'
import Slideshow from '../../components/Demo/Slideshow'

const SlideshowPage = () => {
  const pageSlug = 'slideshow'

  return (
    <Layout pageSlug={pageSlug}>
      <Slideshow />
    </Layout>
  )
}

export default SlideshowPage
